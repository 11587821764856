import { PiletApi } from '@sharefiledev/sharefile-appshell';
import * as components from './chunkLoading';

export function addBlocks(piral: PiletApi) {
	/** Extension name MUST match what is in the UI Manifest {@see ../development/uiManifest.json} */
	const listClientsBlockName = 'urn:block:client-mgt-pilet:list-clients' as const;
	piral.registerExtension(listClientsBlockName, components.AsyncListClients);

	/** Extension name MUST match what is in the UI Manifest {@see ../development/uiManifest.json} */
	const viewClientBlockName = 'urn:block:client-mgt-pilet:view-client' as const;
	piral.registerExtension(viewClientBlockName, components.AsyncViewClient);

	/** Extension name MUST match what is in the UI Manifest {@see ../development/uiManifest.json} */
	const clientIntakeBlockName = 'urn:block:client-mgt-pilet:client-intake' as const;
	piral.registerExtension(clientIntakeBlockName, components.AsyncClientIntake);

	/** Extension name MUST match what is in the UI Manifest {@see ../development/uiManifest.json} */
	const clientContactIntakeBlockName =
		'urn:block:client-mgt-pilet:client-contact-intake' as const;
	piral.registerExtension(
		clientContactIntakeBlockName,
		components.AsyncClientContactIntake
	);

	/** Extension name MUST match what is in the UI Manifest {@see ../development/uiManifest.json} */
	const updateClientBlockName = 'urn:block:client-mgt-pilet:client-update' as const;
	piral.registerExtension(updateClientBlockName, components.AsyncClientUpdate);

	/** Extension name MUST match what is in the UI Manifest {@see ../development/uiManifest.json} */
	const contactsTableBlockName = 'urn:block:client-mgt-pilet:contacts-table' as const;
	piral.registerExtension(contactsTableBlockName, components.AsyncContactsTable);

	/** Extension name MUST match what is in the UI Manifest {@see ../development/uiManifest.json} */
	const clientContactsWidgetBlockName =
		'urn:block:client-mgt-pilet:client-contacts-widget' as const;
	piral.registerExtension(
		clientContactsWidgetBlockName,
		components.AsyncClientContactsWidget
	);

	/** Extension name MUST match what is in the UI Manifest {@see ../development/uiManifest.json} */
	const serviceContactsWidgetBlockName =
		'urn:block:client-mgt-pilet:service-contacts-widget' as const;
	piral.registerExtension(
		serviceContactsWidgetBlockName,
		components.AsyncServiceContactsWidget
	);

	/** Extension name MUST match what is in the UI Manifest {@see ../development/uiManifest.json} */
	const clientAssociateResourcesTagRendererBlockName =
		'urn:block:client-mgt-pilet:client-associate-resources-tag-renderer' as const;
	piral.registerExtension(
		clientAssociateResourcesTagRendererBlockName,
		components.AsyncClientAssociateResources
	);

	/** Extension name MUST match what is in the UI Manifest {@see ../development/uiManifest.json} */
	const suggestedContactsWidgetBlockName =
		'urn:block:client-mgt-pilet:suggested-contacts-widget' as const;
	piral.registerExtension(
		suggestedContactsWidgetBlockName,
		components.AsyncSuggestedContactsWidget
	);

	/** Extension name MUST match what is in the UI Manifest {@see ../development/uiManifest.json} */
	const suggestedClientsWidgetBlockName =
		'urn:block:client-mgt-pilet:suggested-clients' as const;
	piral.registerExtension(
		suggestedClientsWidgetBlockName,
		components.AsyncSuggestedClientWidget
	);
}
